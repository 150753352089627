var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }



  $(document).ready(function() {

  });

  return communiacsModules;

})( communiacsModules || {} );
